import '../App.css';
import '../component/project-detail-empty.css';
import React from 'react';
import InnerHeader from '../component/InnerHeader'
import TopSearch from '../component/top-serarch' 
import { Table } from 'reactstrap';
import Breadcrumbs from '../component/breadcrumbs'
import icon33 from '../images/icon33.svg'
import icon34 from '../images/icon34.svg'
import icon35 from '../images/icon35.svg'



function ProjectDetailEmpty(props) {
  return (
    <>

    <InnerHeader/>
      <section className="project-detail-empty">
        <Breadcrumbs />
        <TopSearch />
        <div className="project-items">
          <div className="table-responsive">
            <Table borderless>
							<thead>
								<tr>
									<th>Name/ID/Description</th>
									<th>Frequency</th>
									<th>Status</th>
									<th>Statistics</th>
									<th>Actions</th>
								</tr>
							</thead>                       
            </Table>
						<div className="project-empty-content">
							<p>Your extraction project is still empty! It's time to have some fun and add one or more bots/scrapers that will do all the hard work while you'll enjoy the results. You have three options. Choose the one the best ﬁts your needs.</p>
							<ul>
								<li>
									<div className="iconBlock">
										<img src={icon33} alt="Icon 33" />
									</div>
									<div className="rightContact">
										<h6>Import Bots/Scrapers</h6>
										<p>Use it if you've already created one or more bots with us and downloaded them on your computer.</p>
										<p>Upload your bots and associate them with your extraction project.</p>
									</div>
								</li>
								<li>
									<div className="iconBlock">
										<img src={icon34} alt="Icon 34" />
									</div>
									<div className="rightContact">
										<h6>ASSOCIATE EXISTING BOTS/SCRAPERS</h6>
										<p>Use it if you created one or more bots/scrapers with us, saved them in your dashboard, but haven't associated them with the extraction project yet.</p>
									</div>
								</li>
								<li>
									<div className="iconBlock">
										<img src={icon35} alt="Icon 35" />
									</div>
									<div className="rightContact">
										<h6>CREATE A NEW BOT/SCRAPER</h6>
										<p>Use it to created and add a brand new bot to your extraction project. Use our visual designer and create limitless web scraping solutions.</p>
										<p>Are you a developer looking for complex and super ﬂexible data extraction solutions? Use the advanced mode and even the bot trainer.</p>
										<p>Are you a data-driven entrepreneur, marketer or researcher? Use our no-code designer with point-and-click functionality and scrape without any coding skills.</p>
										<p>Everyone can also ﬁnd lots of already-made templates to make things simple, faster, yet easier to edit and customize.</p>
									</div>
								</li> 
							</ul>
						</div>
          </div>
        </div>
      </section>
    
    </>    
  );
}
export default ProjectDetailEmpty;