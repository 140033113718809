import '../App.css';
import '../component/new-project.css';
import React from 'react';
import InnerHeader from '../component/InnerHeader'
import TopSearch from '../component/top-serarch' 
import { Form, FormGroup, Input, Label  } from 'reactstrap';
import Icon18Hover from '../images/icon18-hover.svg'


function NewProject(props) {
  return (
    <>
    
    <InnerHeader/>
    <section className="newProject">
      <TopSearch />      
        <div className="newProjectWrap">            
          <h3>You are almost done! Now create your ﬁrst extraction project.</h3>
          <Form>
            <FormGroup className="newFile">
              <Label for="file">
                <span>
                  <img src={Icon18Hover} alt="Icon18Hover" />
                </span>
                <Input id="file" type="file"  />
                <span className="input-text">New Project</span>
              </Label>
            </FormGroup>
          </Form>
        </div>
    </section>
    
    </>
    
  );
}

export default NewProject;