import '../App.css';
import '../component/project-bots-list.css';
import React from 'react';
import InnerHeader from '../component/InnerHeader'
import TopSearch from '../component/top-serarch' 
import { Table } from 'reactstrap';
import Breadcrumbs from '../component/breadcrumbs'
import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom";
import Img1 from '../images/img1.svg';
import icon27 from '../images/icon27.svg';
import icon28 from '../images/icon28.svg';
import icon29 from '../images/icon29.svg';
import icon30 from '../images/icon30.svg';
import icon36 from '../images/icon36.svg';

function ProjectList(props) {
  return (
    <>
    
    <InnerHeader/>

    <section className="project-list">
      <Breadcrumbs />
      <TopSearch />
      <div className="project-items">
        <div className="table-responsive">
          <Table borderless>
            <thead>
              <tr>
                <th>Extraction date</th>
								<th>Nr. of Records</th>
								<th>Statistics</th>
								<th>URLs List</th>
                <th>Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td width="160">
									<div className="left-content">
										<div className="left-content-inner">
                      <h6>20 September, 2021</h6>  
                      <span>ID# 0000033</span>
                    </div>										
									</div>
								</td>
                <td>
                  <span>10,870</span>
                </td>
                <td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
                <td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={icon36} alt="Img" />
									<ReactTooltip />
								</td>								
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
                    <Link data-tip="Duplicate" to="#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
							<tr>
								<td width="160">
									<div className="left-content">
										<div className="left-content-inner">
                      <h6>20 September, 2021</h6>  
                      <span>ID# 0000033</span>
                    </div>										
									</div>
								</td>
                <td>
                  <span>10,870</span>
                </td>
                <td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
                <td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={icon36} alt="Img" />
									<ReactTooltip />
								</td>								
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
                    <Link data-tip="Duplicate" to="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
              <tr>
								<td width="160">
									<div className="left-content">
										<div className="left-content-inner">
                      <h6>20 September, 2021</h6>  
                      <span>ID# 0000033</span>
                    </div>										
									</div>
								</td>
                <td>
                  <span>10,870</span>
                </td>
                <td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
                <td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={icon36} alt="Img" />
									<ReactTooltip />
								</td>								
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
                    <Link data-tip="Duplicate" to="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
						</tbody>
					</Table>
      	</div>
    	</div>
  	</section>
    
    </>    
  );
}
export default ProjectList;