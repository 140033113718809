import '../App.css';
import '../component/project-list.css';
import React from 'react';
import InnerHeader from '../component/InnerHeader'
import TopSearch from '../component/top-serarch' 
import { Table } from 'reactstrap';
import Breadcrumbs from '../component/breadcrumbs'
import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom";
import Img1 from '../images/img1.svg';
import icon25 from '../images/icon25.svg';
import icon26 from '../images/icon26.svg';
import icon27 from '../images/icon27.svg';
import icon28 from '../images/icon28.svg';
import icon29 from '../images/icon29.svg';
import icon30 from '../images/icon30.svg';
import icon31 from '../images/icon31.svg';
import icon32 from '../images/icon32.svg';

function ProjectList(props) {
  return (
    <>
    
    <InnerHeader/>

    <section className="project-list">
      <Breadcrumbs />
      <TopSearch />
      <div className="project-items">
        <div className="table-responsive">
          <Table borderless>
            <thead>
              <tr>
                <th>Name/ID/Description</th>
								<th>Frequency</th>
								<th>Status</th>
								<th>Statistics</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="left-content">
										<div className="imgBlock"></div>
										<div className="item-info">
											<h6>Smart TVs price comparison</h6>
											<span className="item-id">ID# 0001234</span>
											<p>Here, you can read the description of your extraction project. It can be a short sentence or a few paragraphs that help you identify the topic and objective.</p>
											<Link to="/#0" data-tip="Click here or on the title to see the list of bots and datasets">Project details</Link>
											<ReactTooltip />
										</div>
									</div>
								</td>
								<td>
									<span className="blue-btn">Daily</span>
								</td>
								<td>
									<span className="gray-btn">Empty</span>
								</td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon25} alt="icon25" />
										</Link>
										<Link to="/#0">
											<img src={icon26} alt="icon26" />
										</Link>
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<br/>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
										<Link data-tip="Duplicate" to="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link data-tip="Import bots/scrapers (without datasets)" to="/#0">
											<img src={icon31} alt="icon31" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon32} alt="icon32" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div className="left-content">
										<div className="imgBlock"></div>
										<div className="item-info">
											<h6>Smart TVs price comparison</h6>
											<span className="item-id">ID# 0001234</span>
											<p>Here, you can read the description of your extraction project. It can be a short sentence or a few paragraphs that help you identify the topic and objective.</p>
											<Link to="/#0" data-tip="Click here or on the title to see the list of bots and datasets">Project details</Link>
											<ReactTooltip />
										</div>
									</div>
								</td>
								<td>
									<span className="blue-btn">Daily</span>
								</td>
								<td>
									<span className="gray-btn">Empty</span>
								</td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon25} alt="icon25" />
										</Link>
										<Link to="/#0">
											<img src={icon26} alt="icon26" />
										</Link>
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<br/>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
										<Link data-tip="Duplicate" to="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link data-tip="Import bots/scrapers (without datasets)" to="/#0">
											<img src={icon31} alt="icon31" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon32} alt="icon32" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div className="left-content">
										<div className="imgBlock"></div>
										<div className="item-info">
											<h6>Smart TVs price comparison</h6>
											<span className="item-id">ID# 0001234</span>
											<p>Here, you can read the description of your extraction project. It can be a short sentence or a few paragraphs that help you identify the topic and objective.</p>
											<Link to="/#0" data-tip="Click here or on the title to see the list of bots and datasets">Project details</Link>
											<ReactTooltip />
										</div>
									</div>
								</td>
								<td>
									<span className="blue-btn">Daily</span>
								</td>
								<td>
									<span className="gray-btn">Empty</span>
								</td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon25} alt="icon25" />
										</Link>
										<Link to="/#0">
											<img src={icon26} alt="icon26" />
										</Link>
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<br/>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
										<Link data-tip="Duplicate" href="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link data-tip="Import bots/scrapers (without datasets)" href="/#0">
											<img src={icon31} alt="icon31" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon32} alt="icon32" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div className="left-content">
										<div className="imgBlock"></div>
										<div className="item-info">
											<h6>Smart TVs price comparison</h6>
											<span className="item-id">ID# 0001234</span>
											<p>Here, you can read the description of your extraction project. It can be a short sentence or a few paragraphs that help you identify the topic and objective.</p>
											<Link to="/#0" data-tip="Click here or on the title to see the list of bots and datasets">Project details</Link>
											<ReactTooltip />
										</div>
									</div>
								</td>
								<td>
									<span className="blue-btn">Daily</span>
								</td>
								<td>
									<span className="gray-btn">Empty</span>
								</td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon25} alt="icon25" />
										</Link>
										<Link to="/#0">
											<img src={icon26} alt="icon26" />
										</Link>
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<br/>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
										<Link data-tip="Duplicate" href="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link data-tip="Import bots/scrapers (without datasets)" href="/#0">
											<img src={icon31} alt="icon31" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon32} alt="icon32" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div className="left-content">
										<div className="imgBlock"></div>
										<div className="item-info">
											<h6>Smart TVs price comparison</h6>
											<span className="item-id">ID# 0001234</span>
											<p>Here, you can read the description of your extraction project. It can be a short sentence or a few paragraphs that help you identify the topic and objective.</p>
											<Link to="/#0" data-tip="Click here or on the title to see the list of bots and datasets">Project details</Link>
											<ReactTooltip />
										</div>
									</div>
								</td>
								<td>
									<span className="blue-btn">Daily</span>
								</td>
								<td>
									<span className="gray-btn">Empty</span>
								</td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon25} alt="icon25" />
										</Link>
										<Link to="/#0">
											<img src={icon26} alt="icon26" />
										</Link>
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<br/>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
										<Link data-tip="Duplicate" href="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link data-tip="Import bots/scrapers (without datasets)" href="/#0">
											<img src={icon31} alt="icon31" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon32} alt="icon32" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div className="left-content">
										<div className="imgBlock"></div>
										<div className="item-info">
											<h6>Smart TVs price comparison</h6>
											<span className="item-id">ID# 0001234</span>
											<p>Here, you can read the description of your extraction project. It can be a short sentence or a few paragraphs that help you identify the topic and objective.</p>
											<Link to="/#0" data-tip="Click here or on the title to see the list of bots and datasets">Project details</Link>
											<ReactTooltip />
										</div>
									</div>
								</td>
								<td>
									<span className="blue-btn">Daily</span>
								</td>
								<td>
									<span className="gray-btn">Empty</span>
								</td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon25} alt="icon25" />
										</Link>
										<Link to="/#0">
											<img src={icon26} alt="icon26" />
										</Link>
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
										<Link to="/#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<br/>
										<Link to="/#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
										<Link data-tip="Duplicate" href="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link data-tip="Import bots/scrapers (without datasets)" href="/#0">
											<img src={icon31} alt="icon31" />
											<ReactTooltip />
										</Link>
										<Link to="/#0">
											<img src={icon32} alt="icon32" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
						</tbody>
					</Table>
      	</div>
    	</div>
  	</section>
    
    </>    
  );
}
export default ProjectList;