import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './component/Login';
import SelfServiceHome from './component/SelfServiceHome';
import NewProject from './component/new-project';
import ProjectList from './component/project-list';
import ProjectDetailEmpty from './component/project-detail-empty';
import ProjectBotsList from './component/project-bots-list';
import SingalBotsDetailEmpty from './component/singal-bots-detail-empty';
import SingleBotDatasets from './component/single-bot-datasets';
import CategoriesListEmpty from './component/categories-list-empty';

class App extends Component {
  render() {
    return (
    <Router>          
      <Switch>
        <Route exact path='/' component={Login} />
        <Route path='/SelfServiceHome' component={SelfServiceHome} />
        <Route path='/new-project' component={NewProject} />
        <Route path='/project-list' component={ProjectList} />
        <Route path='/project-detail-empty' component={ProjectDetailEmpty} />
        <Route path='/project-bots-list' component={ProjectBotsList} />
        <Route path='/singal-bots-detail-empty' component={SingalBotsDetailEmpty} />
        <Route path='/single-bot-datasets' component={SingleBotDatasets} />
        <Route path='/categories-list-empty' component={CategoriesListEmpty} />             
      </Switch>
    </Router>
    );
  }
}

export default App;