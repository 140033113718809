import '../App.css';
import '../component/inner-header.css';
import React from 'react';
import { Row, Col} from 'reactstrap';
import {Link} from "react-router-dom";
import innerlogo from '../images/innerlogo.png'
import Icon2 from '../images/icon2.svg'
import Icon3 from '../images/icon3.svg'
import Icon7 from '../images/icon7.svg'
import Icon8 from '../images/icon8.svg'
import Icon9 from '../images/icon9.svg'
import Icon10 from '../images/icon10.svg'
import Icon11 from '../images/icon11.svg'
import Icon17 from '../images/icon17.svg'
import Icon17Hover from '../images/icon17-hover.svg'
import Icon18 from '../images/icon18.svg'
import Icon18Hover from '../images/icon18-hover.svg'
import Icon19 from '../images/icon19.svg'
import Icon19Hover from '../images/icon19-hover.svg'
import Icon20 from '../images/icon20.svg'
import Icon20Hover from '../images/icon20-hover.svg'
import Icon21 from '../images/icon21.svg'
import Icon21Hover from '../images/icon21-hover.svg'

function InnerHeader(props) {
	// for add or remove class onm click
	const handelClick = (e)=>{
		Array.from(document.getElementsByClassName('forRemoveClass')).forEach((el) => {
			el.classList.remove('active')
		})
		e.target.parentElement.classList.add('active')
	}

  


  return (
		<>
			<header className="headerTwo">
				<Row>
					<Col xl="5">
            <Link to="/">
							<img src={innerlogo} alt="Logo" />
						</Link>
					</Col>
					<Col xl="2">

					</Col>
					<Col xl="5">
						<ul className="header-right">
							<li>
                <Link to="/" className="topMenu active">
									<img src={Icon7} alt="Icon" />
									<span className="login-text">Home</span>
                </Link>
							</li>
							<li>
                <Link to="#0" className="topMenu">
									  <img src={Icon8} alt="Icon" />
										<span className="login-text">Profile</span>
								</Link>
							</li>
							<li>
                <Link to="#0" className="topMenu">
									  <img src={Icon9} alt="Icon" />
										<span className="login-text">Plans</span>
								</Link>
							</li>
							<li>
                <Link to="#0" className="topMenu">
									  <img src={Icon10} alt="Icon" />
										<span className="login-text">Orders</span>
								</Link>
							</li>
							<li>
                <Link to="/project-list" className="topMenu">
									  <img src={Icon11} alt="Icon" />
										<span className="login-text">Projects</span>
                </Link>
							</li>
							
							<li>
                <Link to="#0" className="topMenu">
									  <img src={Icon3} alt="Icon" />
										<span className="login-text">Help</span>
								</Link>
							</li>
              <li>
                <Link to="#0" className="topMenu">
									  <img src={Icon2} alt="Icon" />
										<span className="login-text">LogOut</span>
									</Link>
							</li>
            </ul>
					</Col>
				</Row>
				<div className="header-menu">
					<ul>
						<li  onClick={handelClick}>
							<Link className="forRemoveClass active" to="/project-list">
                <img src={Icon17} alt="Icon" className="default-img" />
                <img src={Icon17Hover} alt="Icon" className="hover-img"/>
								<span className="menu-name">Projects</span>
							</Link>
						</li>
						<li onClick={handelClick}>
							<Link className="forRemoveClass" to="/new-project">
                <img src={Icon18} alt="Icon" className="default-img" />
                <img src={Icon18Hover} alt="Icon" className="hover-img"/>
								<span className="menu-name">New Project</span>
							</Link>
						</li>
						<li >
              <Link className="forRemoveClass" to="/#0">
                <img src={Icon19} alt="Icon" className="default-img" />
                <img src={Icon19Hover} alt="Icon" className="hover-img"/>
								<span className="menu-name">Scrapers</span>
							</Link>
						</li>
						<li>
              <Link className="forRemoveClass" to="/#0">
                <img src={Icon20} alt="Icon" className="default-img"/>
                <img src={Icon20Hover} alt="Icon" className="hover-img"/>
								<span className="menu-name">New Category</span>
							</Link>
						</li>
						<li>
							<Link className="forRemoveClass" to="/#0">
                <img src={Icon21} alt="Icon" className="default-img"/>
                <img src={Icon21Hover} alt="Icon" className="hover-img"/>
								<span className="menu-name">New Scraper</span>
							</Link>
						</li>
					</ul>
				</div>
			</header>
		</>
	);
}

export default InnerHeader;
