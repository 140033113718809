import '../App.css';
import '../component/project-bots-list.css';
import React from 'react';
import {Link} from "react-router-dom";
import InnerHeader from '../component/InnerHeader'
import TopSearch from '../component/top-serarch' 
import { Table } from 'reactstrap';
import Breadcrumbs from '../component/breadcrumbs'
import ReactTooltip from 'react-tooltip';
import Img1 from '../images/img1.svg';
import icon27 from '../images/icon27.svg';
import icon28 from '../images/icon28.svg';
import icon29 from '../images/icon29.svg';
import icon30 from '../images/icon30.svg';

function ProjectList(props) {
  return (
    <>
    
    <InnerHeader/>

    <section className="project-list">
      <Breadcrumbs />
      <TopSearch />
      <div className="project-items">
        <div className="table-responsive">
          <Table borderless>
            <thead>
              <tr>
                <th>Name/ID</th>
								<th>Description</th>
								<th>Statistics</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td width="160">
									<div className="left-content">
										<div className="left-content-inner">
                      <h6>Walmart Smart TVs</h6>  
                      <span>ID# 0000047</span>
                    </div>										
									</div>
								</td>
                <td width="580">
                  <div className="item-info">
                    <p>Here, you can read the description of your extraction bot/scraper. It can consist of a short sentence or a few paragraphs that help you identify the objective, data source, type of records and elements to extract.</p>
                    <Link to="/#0" data-tip="Click here or on the title to see the list of bots and datasets">Bot/scraper details/data sets</Link>
                    <ReactTooltip />
                  </div>
                </td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="/#0">
											<img src={icon27} alt="icon27" />
										</Link>
                    <Link data-tip="Duplicate" to="/#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link to="#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<Link to="#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
							<tr>
								<td width="160">
									<div className="left-content">
                    <div className="left-content-inner">
                      <h6>Walmart Smart TVs</h6>  
                      <span>ID# 0000047</span>
                    </div>										
									</div>
								</td>
                <td width="580">
                  <div className="item-info">
                    <p>Here, you can read the description of your extraction bot/scraper. It can consist of a short sentence or a few paragraphs that help you identify the objective, data source, type of records and elements to extract.</p>
                    <Link to="#0" data-tip="Click here or on the title to see the list of bots and datasets">Bot/scraper details/data sets</Link>
                    <ReactTooltip />
                  </div>
                </td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="#0">
											<img src={icon27} alt="icon27" />
										</Link>
                    <Link data-tip="Duplicate" to="#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link to="#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<Link to="#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
              <tr>
								<td width="160">
									<div className="left-content">
                    <div className="left-content-inner">
                      <h6>Walmart Smart TVs</h6>  
                      <span>ID# 0000047</span>
                    </div>										
									</div>
								</td>
                <td width="580">
                  <div className="item-info">
                    <p>Here, you can read the description of your extraction bot/scraper. It can consist of a short sentence or a few paragraphs that help you identify the objective, data source, type of records and elements to extract.</p>
                    <Link to="#0" data-tip="Click here or on the title to see the list of bots and datasets">Bot/scraper details/data sets</Link>
                    <ReactTooltip />
                  </div>
                </td>
								<td>
									<img data-tip="Click to see project info: creation date, latest extraction, scheduled start &amp; end date, nr. scrapers, nr. data sets, nr. records this month." src={Img1} alt="Img" />
									<ReactTooltip />
								</td>
								<td>
									<div className="action-group">
										<Link to="#0">
											<img src={icon27} alt="icon27" />
										</Link>
                    <Link data-tip="Duplicate" to="#0">
											<img src={icon30} alt="icon30" />
											<ReactTooltip />
										</Link>
										<Link to="#0">
											<img src={icon28} alt="icon28" />
										</Link>
										<Link to="#0">
											<img src={icon29} alt="icon29" />
											<div className="action-option">
												<span data-tip="It opens the import /associate bot modal" >Existing bots/scrapers</span>
												<span data-tip="It opens the 'New Scraper' tab">New bots/scrapers</span>
												<ReactTooltip />
												<ReactTooltip />
											</div>
										</Link>
									</div>
								</td>
							</tr>
						</tbody>
					</Table>
      	</div>
    	</div>
  	</section>
    
    </>    
  );
}
export default ProjectList;